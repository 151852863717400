@import "styles/styles.scss";
.banner {
  outline: none;
  background-color: $color-neutral-medium;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid $color-neutral-medium;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;

  @include desktop-up {
    margin-bottom: 80px;
  }

  .banner__container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
  }
}

.banner__FullBleed {
  margin-top: -$header-height-regular;

  @include desktop-up {
    margin-top: -$header-height-large;
  }
}

.banner__ErrorPage {
  h1, h3 {
    color: $color-primary !important;
  }
}



@import "styles/styles.scss";
/** Specific Banner MediaQueries **/
@mixin tablet-down-landscape {
  @media (max-width: #{$breakpoint-tablet-pro - 1px}) and (orientation: landscape) and (max-height: 500px) {
    @content;
  }
}

@mixin mobile-down-landscape {
  @media (max-width: #{$breakpoint-tablet - 1px}) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

.bannerItem {
  background-color: $color-neutral-medium;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  max-height: 1000px;
  width: 100%;
  height: calc(100dvh - 150px);

  &.bannerItem_squished:not(.bannerItem__FullBleed) {
    height: calc(
      100dvh - #{$flashbanner-height-mobile + $header-height-regular}
    );

    @include mobile-down-landscape {
      min-height: 300px;
    }

    @include tablet-up {
      height: calc(
        100dvh - #{$flashbanner-height-desktop + $header-height-regular}
      );
    }

    @include desktop-up {
      height: calc(
        100dvh - #{$flashbanner-height-desktop + $header-height-large}
      );
    }
  }

  &.bannerItem__FullBleed {
    height: calc(100dvh - #{$flashbanner-height-mobile});

    @include tablet-up {
      height: 100dvh;
      height: 100vh; // fallback for browsers not supporting dvh
      width: 100dvw;
    }

    &.bannerItem_squished {
      @include tablet-up {
        height: calc(
          100dvh - #{$flashbanner-height-desktop}
        );
        height: calc(
          100vh - #{$flashbanner-height-desktop}
        ); // fallback for browsers not supporting dvh
      }
    }

    .bannerItem__container {
      @include desktop-up {
        padding-top: $header-height-large;
      }
    }
    
  }

  &.bannerItem_squished.bannerItem__mobileStacked {
    @include mobile-down {
      background-color: $color-neutral-min;
      height: auto;

      .bannerItem__media,
      .bannerItem__mediaLink {
        height: auto;
        position: relative;

        img {
          height: auto;
        }
      }

      .bannerItem__content {
        margin-top: 30px;
        position: static;
      }

      .bannerItem__description {
        text-align: center;
      }
    }
  }


  @media (max-width: 850px) and (orientation: landscape) {
    height: 500px;
  }

  @media (max-aspect-ratio: 2048/1109) and (min-width: 1000px) {
    height: auto;
  }

  @include desktop-up {
    height: calc(100vh - #{$flashbanner-height-desktop + 30px});
  }

  @include desktopX1-up {
    max-height: 1200px;
  }

  @include desktopX3-up {
    max-height: none;
  }

  @include tablet-only-portrait {
    height: 70vh;
  }

  .bannerItem__media,
  .bannerItem__mediaLink {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    :global(.lazyload-wrapper), /* to aplly styles to the lazyload wrapper <div> */
    img,
    video {
      height: 100%;
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .bannerItem__title {
    @include titleMedium;

    color: $color-neutral-min;
    margin-bottom: 10px;
    max-width: 400px;

    @media (max-width: 850px) and (orientation: landscape) {
      @include titleLarge;
    }

    @include tablet-up {
      @include titleLargeX1;

      margin-bottom: 15px;
      max-width: 465px;
    }

    @include desktop-up {
      @include titleLargeX3;

      margin-bottom: 20px;
    }

    span {
      display: block;
    }

    &:global(.bannerItem__title--bright) {
      color: $color-primary;
    }
  }

  .bannerItem__title_largeMargin {
    > *:nth-child(1) {
      margin-top: 14px;

      @include desktop-up {
        margin-top: 100px;
      }
    }
  }

  .bannerItem__description {
    @include tablet-up {
      max-width: 250px;
    }

    @include desktop-up {
      max-width: 500px;
    }
  }

  .bannerItem__subtitle {
    @include bodyCopyRegular(true);

    color: $color-neutral-min;
    max-width: 330px;

    @include tablet-up {
      max-width: 465px;
    }

    @include desktop-up {
      @include bodyCopyLarge;
    }

    &.bannerItem__subtitle_alignCenter {
      text-align: center;

      @include tablet-up {
        max-width: 350px;
      }
    }

    &:global(.bannerItem__subtitle--bright) {
      color: $color-primary;
    }

    + .bannerItem__description {
      margin-top: 15px;
    }
  }

  .bannerItem__buttonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    pointer-events: all;

    .bannerItem__button {
      width: 100%;

      &:first-child:not(:last-child) {
        margin-bottom: 20px;

        @include mobile-down-landscape {
          width: 100%;
        }

        @include tablet-down-landscape {
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }

    @include tablet-down-landscape {
      flex-direction: row;
      justify-content: center;
    }

    @include mobile-down {
      width: 100%;
    }

    @include tablet-up {
      margin-top: 30px;
    }

    @include desktop-up {
      flex-direction: row;
      margin-top: 50px;

      .bannerItem__button {
        width: auto;

        &:last-child {
          margin-right: 0;
        }

        &:first-child:not(:last-child) {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }
  }

  .bannerItem__container {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    max-width: 1330px;
    width: 100%;
    padding: 0;

    @include mobile-small-down {
      flex-wrap: nowrap;
    }
  }

  .bannerItem__content {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 30px 50px;
    pointer-events: none;

    &.buttonOnly {
      padding-bottom: 50px;

      @include mobile-small-down {
        padding-bottom: 20px;
      }
    }

    @include tablet-up {
      padding: 0 60px;
    }

    @include desktop-up {
      padding: 0 80px;
    }

    @include tablet-only-portrait {
      padding-bottom: 80px;
    }

    @include desktopX2-up {
      padding: 0 18px;
      margin: 0 auto;
      max-width: $max-width-large;
    }

    .bannerItem__container {
      @include desktopX2-up {
        max-width: 100%;
      }
    }

    &.bannerItemVerticalMobileDownBottom {
      @include mobile-down {
        height: auto;

        .bannerItem__content {
          display: flex;
          position: relative;
          padding-top: 400px;
          padding-top: 380px;

          @include mobile-small-down {
            padding-top: 300px;
          }
        }

        .bannerItem__image {
          left: 50%;
          transform: translateX(-50%);
        }

        .bannerItem__buttonsWrapper {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      @include tablet-down-landscape {
        height: auto;
        min-height: 330px;

        .bannerItem__content {
          padding-bottom: 50px;
        }
      }
    }

    &.bannerItemVerticalTabletCenter {
      @include tablet-only-portrait {
        height: 70vh;

        .bannerItem__content {
          padding-bottom: 0;
        }
      }

      .bannerItem__title {
        max-width: inherit;
      }
    }

    @include mobile-down {
      &:global(.vertical-center-mobile-down) {
        padding-bottom: 0;

        .bannerItem__container {
          justify-content: center;
        }
      }

      &:global(.vertical-end-mobile-down) {
        .bannerItem__container {
          justify-content: flex-end;
        }
      }

      &:global(.horizontal-center-mobile-down) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: center;
        }

        .bannerItem__buttonsWrapper,
        .bannerItem__container {
          align-items: center;
        }
      }
    }

    @include tablet-only {
      &:global(.vertical-center-tablet-only) {
        padding-bottom: 80px;

        .bannerItem__container {
          justify-content: center;
        }
      }

      &:global(.vertical-end-tablet-only) {
        padding-bottom: 80px;

        .bannerItem__container {
          justify-content: flex-end;
        }
      }

      &:global(.horizontal-start-tablet-only) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: left;
        }

        .bannerItem__container {
          align-items: flex-start;
        }
      }

      &:global(.horizontal-center-tablet-only) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: center;
        }

        .bannerItem__container {
          align-items: center;
        }
      }

      &:global(.horizontal-end-tablet-only) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: right;
        }

        .bannerItem__container {
          align-items: flex-end;
        }
      }
    }

    @include desktop-up {
      &:global(.vertical-center-desktop-up) {
        .bannerItem__container {
          justify-content: center;
        }
      }

      &:global(.vertical-end-desktop-up) {
        &.bannerItem__content {
          padding-bottom: 80px;
        }

        .bannerItem__container {
          justify-content: flex-end;
        }
      }

      &:global(.horizontal-start-desktop-up) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: left;
        }

        .bannerItem__container {
          align-items: flex-start;
        }
      }

      &:global(.horizontal-center-desktop-up) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: center;
        }

        .bannerItem__buttonsWrapper {
          align-items: center;
        }

        .bannerItem__container {
          align-items: center;
        }
      }

      &:global(.horizontal-end-desktop-up) {
        .bannerItem__title,
        .bannerItem__subtitle {
          text-align: right;
        }

        .bannerItem__container {
          align-items: flex-end;
        }
      }
    }

    @include desktopX2-up {
      &:global(.vertical-end-desktop-up) {
        &.bannerItem__content {
          padding-bottom: 160px;
        }
      }
    }

    &.bannerItem__content_gradient {
      @include mobile-down {
        background-image: linear-gradient(
          to top,
          rgb(0 0 0 / 50%),
          rgb(0 0 0 / 50%) 20%,
          rgb(0 0 0 / 0%) 50%
        );
      }
    }
  }

  .bannerItem__badge {
    margin-bottom: 15px;
  }
}

.abTest__bannerImage {
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  z-index: 1;
}
